/* eslint-disable @typescript-eslint/no-unused-vars */
/*

👋 “Hey” from the Praxis team!

'apiConfig' is one pattern that can be used to set environment-based values at build time.
This happens by creating an environment variable REACT_APP_ENV, and then matching corresponding
keys to each environment in the 'envConfigs' object below. To learn more about how to set
REACT_APP_ENV, view the README generated with this app.


For an alternative pattern - using TAP configuration to set environment-based values at cluster initialization - see:
@praxis/component-runtime-env - https://praxis.prod.target.com/components/runtime-env

*/

import merge from 'lodash/merge'
import { AuthenticationProps } from '@praxis/component-auth'

/**
 * This is the interface of our final export.
   - Extend this as needed with strongly typed values for the best support within your app.
   - TypeScript Interface docs https://www.typescriptlang.org/docs/handbook/interfaces.html
 */
export interface ApiConfig extends CommonConfig {
  auth?: AuthenticationProps // Overwrite the commonConfig. In this case making it more strict.
  analytics: any
  api: {
    edge: string
    externalUrl: string
    key: string
  }
  entitlements?: any
  mfes?: any
}

// This is the type of keys that may be shared across environments.
type CommonConfig = {
  auth?: AuthenticationProps
  // readonly anotherApi?: AnotherApi // Attempt to strongly type the configuration you expect to consume.
  // readonly anotherApi?: any // Use 'any' as a last resort - intellisense will be limited.

  analytics: {
    url: string
    schemaId?: string | null
    autoWrap: Boolean
    persistMethod: string
    retries: Number
    referrer: string
  }

  api: {
    edge: string
    externalUrl: string
    key: string
  }

  entitlements?: {
    polWrite: string[]
    polRead: string[]
    tgtInternalRead: string[]
    tgtInternalWrite: string[]
    merchVendorUser: string
    consolidatorUser: string
    reverseLogisticsUser: string
  }

  mfes?: [
    {
      name: string
      to: string
      prefix: string
      routePath: string
      childRoutes: string[]
      host: string
      hostUrl: string
      externalHost: string
      externalHostUrl: string
    },
  ]
}

// // An example custom type.
// type AnotherApi = {
//   host?: string
//   root?: string
// }

// Defines the environments we are building for.
// These must match the top-level keys of envConfigs.
//
type ReactAppEnv = 'dev' | 'stg' | 'prod'
type EnvConfigs = {
  readonly [key in ReactAppEnv]: ApiConfig
}

const POL_ACCESS = {
  MERCH_VENDOR: 'APP-SIQ-MERCHANDISEVENDOR',
  CONSOLIDATOR: 'APP-SIQ-CONSOLIDATOR',
}

const STG_ACCESS = {
  INTERNAL_EDIT: 'APP-SHIPIQUIAPI-EDIT-STG',
  INTERNAL_ADMIN: 'APP-SHIPIQUIAPI-ADMIN-STG',
  INTERNAL_ACCESS: 'APP-SHIPIQUIAPI-ACCESS-STG',
  REVERSE_LOGISTICS: 'APP-SHIPIQUIAPI-REVERSELOG-STG',
}

const PROD_ACCESS = {
  INTERNAL_EDIT: 'APP-SHIPIQUIAPI-EDIT-PRD',
  INTERNAL_ADMIN: 'APP-SHIPIQUIAPI-ADMIN-PRD',
  INTERNAL_ACCESS: 'APP-SHIPIQUIAPI-ACCESS-PRD',
  REVERSE_LOGISTICS: 'APP-SHIPIQUIAPI-REVERSELOG-PRD',
}

// Configurations shared between most or all environments can be stored in commonConfig.
const commonConfig: CommonConfig = {
  auth: {
    authorizationUrl: window.location.hostname.includes('partnersonline.com')
      ? 'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1'
      : 'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
    logoutUrl: window.location.hostname.includes('partnersonline.com')
      ? 'https://oauth.iam.perf.partnersonline.com/login/responses/logoff.html'
      : 'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
    clientId: 'shipiq_npe_im',
  },
  analytics: {
    schemaId: null,
    autoWrap: true,
    persistMethod: 'localStorage',
    retries: 0,
    referrer: '',
    url: '',
  },
  api: {
    edge: 'https://shipiquiapidev-core.dev.target.com/vendor_transportation_adjustments',
    externalUrl:
      'https://stage-api.target.com/vendor_transportation_adjustments',
    key: '695bd04c48647737d91b1d94b8d469df654626ac',
  },
  entitlements: {
    polWrite: [POL_ACCESS.MERCH_VENDOR, POL_ACCESS.CONSOLIDATOR],
    polRead: [POL_ACCESS.MERCH_VENDOR, POL_ACCESS.CONSOLIDATOR],
    merchVendorUser: POL_ACCESS.MERCH_VENDOR,
    consolidatorUser: POL_ACCESS.CONSOLIDATOR,
    reverseLogisticsUser: STG_ACCESS.REVERSE_LOGISTICS,
    tgtInternalRead: [
      STG_ACCESS.INTERNAL_ADMIN,
      STG_ACCESS.INTERNAL_ACCESS,
      STG_ACCESS.INTERNAL_EDIT,
    ],
    tgtInternalWrite: [STG_ACCESS.INTERNAL_ADMIN, STG_ACCESS.INTERNAL_EDIT],
  },
  mfes: [
    {
      name: 'Feature Upvote',
      to: '/featureupvote/',
      prefix: '/featureupvote',
      routePath: '/featureupvote/*',
      childRoutes: [],
      host: 'featureupvote@https://featureupvoteui-shipiq.dev.target.com/remoteEntry.js',
      hostUrl: 'https://featureupvoteui-shipiq.dev.target.com/',
      externalHost:
        'featureupvote@https://featureupvoteui-shipiq.perf.partnersonline.com/remoteEntry.js',
      externalHostUrl:
        'https://featureupvoteui-shipiq.perf.partnersonline.com/',
    },
  ],
}

const envConfigs: EnvConfigs = {
  // 'dev' is the default development environment set by .env.development and the default pipeline in .vela.yml.
  dev: {
    analytics: {
      appName: 'shipiqnpe-dev',
      eventManagerKey: 'shipiqnpe-dev',
      apiKey: '695bd04c48647737d91b1d94b8d469df654626ac',
      url: 'https://stage-api.target.com/consumers/v1/ingest/internal/internal_app',
    },
    api: {
      edge: 'https://shipiquiapidev-core.dev.target.com/vendor_transportation_adjustments',
      externalUrl:
        'https://stage-api.target.com/vendor_transportation_adjustments',
      key: '695bd04c48647737d91b1d94b8d469df654626ac',
    },
  },
  stg: {
    analytics: {
      appName: 'shipiqnpe-stg',
      eventManagerKey: 'shipiqnpe-stg',
      apiKey: '695bd04c48647737d91b1d94b8d469df654626ac',
      url: 'https://stage-api.target.com/consumers/v1/ingest/internal/internal_app',
    },
    api: {
      edge: 'https://shipiquiapi-core.dev.target.com/vendor_transportation_adjustments',
      externalUrl:
        'https://stage-api.target.com/vendor_transportation_adjustments',
      key: '695bd04c48647737d91b1d94b8d469df654626ac',
    },
  },
  prod: {
    auth: {
      // Keys in envConfigs will overwrite keys from commonConfig.
      authorizationUrl: window.location.hostname.includes('partnersonline.com')
        ? 'https://oauth.iam.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1'
        : 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      logoutUrl: window.location.hostname.includes('partnersonline.com')
        ? 'https://oauth.iam.partnersonline.com/login/responses/logoff.html'
        : 'https://logonservices.iam.target.com/login/responses/logoff.html',
      clientId: 'shipiq_prod_im',
    },
    api: {
      edge: 'https://shipiquiapi-core.prod.target.com/vendor_transportation_adjustments',
      externalUrl: 'https://api.target.com/vendor_transportation_adjustments',
      key: '077008633b33e2b8afacb08b8c8387f073275dfe',
    },
    analytics: {
      appName: 'shipiq-prod',
      eventManagerKey: 'shipiq-prod',
      apiKey: '077008633b33e2b8afacb08b8c8387f073275dfe',
      url: 'https://api.target.com/consumers/v1/ingest/internal/internal_app',
    },
    entitlements: {
      polWrite: [POL_ACCESS.MERCH_VENDOR, POL_ACCESS.CONSOLIDATOR],
      polRead: [POL_ACCESS.MERCH_VENDOR, POL_ACCESS.CONSOLIDATOR],
      merchVendorUser: POL_ACCESS.MERCH_VENDOR,
      consolidatorUser: POL_ACCESS.CONSOLIDATOR,
      reverseLogisticsUser: PROD_ACCESS.REVERSE_LOGISTICS,
      tgtInternalRead: [
        PROD_ACCESS.INTERNAL_ADMIN,
        PROD_ACCESS.INTERNAL_ACCESS,
        PROD_ACCESS.INTERNAL_EDIT,
      ],
      tgtInternalWrite: [PROD_ACCESS.INTERNAL_ADMIN, PROD_ACCESS.INTERNAL_EDIT],
    },
    mfes: [
      {
        name: 'Feature Upvote',
        to: '/featureupvote/',
        prefix: '/featureupvote',
        routePath: '/featureupvote/*',
        childRoutes: [],
        host: 'featureupvote@https://featureupvoteui-shipiq.prod.target.com/remoteEntry.js',
        hostUrl: 'https://featureupvoteui-shipiq.prod.target.com/',
        externalHost:
          'featureupvote@https://featureupvoteui-shipiq.partnersonline.com/remoteEntry.js',
        externalHostUrl: 'https://featureupvoteui-shipiq.partnersonline.com/',
      },
    ],
  },
}

if (window.location.hostname.includes('partnersonline.com')) {
  commonConfig.mfes?.forEach((mfe: any) => {
    mfe.host = mfe.externalHost
    mfe.hostUrl = mfe.externalHostUrl
  })

  Object.entries(envConfigs).forEach(([_env, config]) => {
    config.api.edge = config.api.externalUrl
  })

  envConfigs.prod.mfes.forEach((mfe: any) => {
    mfe.host = mfe.externalHost
    mfe.hostUrl = mfe.externalHostUrl
  })
}

const appEnv: ReactAppEnv = (process.env.REACT_APP_ENV as ReactAppEnv) || 'dev'
const config = appEnv != null ? envConfigs[appEnv] : {}

// commonConfig and the config for the matching REACT_APP_ENV are combined.
// Values in the environment-specific config will overwrite commonConfig keys if they share the same name.
const apiConfig: ApiConfig = merge(commonConfig, config)

export default apiConfig
