import validate from 'validate.js'
import {
  LocationDetailsInterface,
  PurchaseOrderInterface,
} from '../../common/types'

export const baseDimensionConstraints = {
  appointmentNumber: {
    format: {
      pattern: '^$|^[a-zA-Z0-9\\-]{0,120}$', // allows A-Z, a-z, 0-9 and hyphens, with character limit from zero to 120
      message:
        'Can only contain numbers, letters and dashes. Max Length 120 characters',
    },
  },
  bol: {
    format: {
      pattern: '^$|^[a-zA-Z0-9]+$',
      message: 'Can only contain alphanumeric characters.',
    },
  },
  carton: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      lessThanOrEqualTo: 200000,
      message: 'Must be an integer between 1 and 200,000 cartons',
    },
  },
}

export const foodDistributionCenterDimensionConstraints = {
  ...baseDimensionConstraints,
  totalVol: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      lessThanOrEqualTo: 3752,
      message: 'Must be an integer between 1 and 3,752 cubic feet',
    },
  },
  totalWt: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      lessThanOrEqualTo: 42500,
      message: 'Must be an integer between 1 and 42,500 pounds',
    },
  },
  pallet: {
    numericality: {
      greaterThan: 0,
      lessThanOrEqualTo: 28,
      message:
        'Must be between 0.01 and 28 pallet spaces, and have at most 2 decimal places.',
    },
    length: (value: number) => hasTwoDecimalPlaces(value),
  },
}

export const nonFoodDistributionCenterDimensionConstraints = {
  ...baseDimensionConstraints,
  totalVol: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      lessThanOrEqualTo: 3500,
      message: 'Must be an integer between 1 and 3,500 cubic feet',
    },
  },
  totalWt: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      lessThanOrEqualTo: 45000,
      message: 'Must be an integer between 1 and 45,000 pounds',
    },
  },
  pallet: {
    numericality: {
      greaterThan: 0,
      lessThanOrEqualTo: 26,
      message:
        'Must be between 0.01 and 26 pallet spaces, and have at most 2 decimal places.',
    },
    length: (value: number) => hasTwoDecimalPlaces(value),
  },
}

export const reverseShipmentConstraints = {
  ...nonFoodDistributionCenterDimensionConstraints,
  pallet: {
    numericality: {
      onlyInteger: true,
      greaterThan: 0,
      lessThanOrEqualTo: 52,
      message: 'Must be a whole number up to 52 pallets',
    },
  },
  rtvsNumber: {
    format: {
      pattern: '^RTVS{8}$',
      message: 'Must contain RTVS followed by 8 digits',
    },
  },
  returnAuthorizationNumber: {
    format: {
      pattern: '^$|^[a-zA-Z0-9\\-]{0,120}$', // allows A-Z, a-z, 0-9 and hyphens, with character limit from zero to 120
      message:
        'Can only contain numbers, letters and dashes. Max Length 120 characters',
    },
  },
}

export const hasTwoDecimalPlaces = (
  value: number,
): { message: string } | null => {
  if (
    !value ||
    !value.toString().split('.')[1] ||
    value.toString().split('.')[1].length <= 2
  ) {
    return null
  } else {
    return { message: 'Must have at most 2 decimal places' }
  }
}

export const isFailedValidation = (
  value: any,
  field: string,
  isFDC: boolean,
  isReverse: boolean = false,
) => {
  const toBeValidated = { [field]: value }
  let hasValidationError
  if (isFDC) {
    hasValidationError = validate(
      toBeValidated,
      foodDistributionCenterDimensionConstraints,
    )
  } else if (isReverse) {
    hasValidationError = validate(toBeValidated, reverseShipmentConstraints)
  } else {
    hasValidationError = validate(
      toBeValidated,
      nonFoodDistributionCenterDimensionConstraints,
    )
  }
  return !!hasValidationError
}

export const locationDataToRow = (
  locationData: LocationDetailsInterface,
  poData: PurchaseOrderInterface,
): LocationDetailsInterface => {
  return {
    ...locationData,
    // locationData.addressLine includes address line 2, so is preferred to render vs poData.street
    addressLine: locationData.addressLine ?? poData.street,
    country: poData.country ?? undefined,
    state: poData.state ?? locationData.state,
    vendorName: poData.vendorName,
    zipCode: poData.zipCode ?? locationData.zipCode,
  }
}
