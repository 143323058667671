import { ShipmentRowInterface } from 'common/types'

export const shipmentsModified = (shipment: ShipmentRowInterface) => {
  return (
    shipment.toBeCancelled ||
    ((shipment.isModified || shipment.isReviewed) &&
      !shipment.hasInvalidDimensions)
  )
}

export const enhancedReverseLogisticsDestinations = (
  destinations?: any[],
  reverseDestinationsMetaData?: any[],
) => {
  if (!destinations) {
    return []
  }
  if (!reverseDestinationsMetaData) {
    return destinations?.map((destination) => ({
      id: destination.id,
      label: destination.id,
    }))
  } else {
    const destinationMetaDataMap = new Map(
      reverseDestinationsMetaData.map((metaData: any) => [
        metaData.tmLocId,
        metaData,
      ]),
    )
    return destinations.reduce((acc: any[], destination: any) => {
      const destinationMetaData = destinationMetaDataMap.get(destination.id)
      if (destinationMetaData) {
        return [
          ...acc,
          {
            ...destination,
            label: `${destinationMetaData.vendorName || ''} - ${
              destinationMetaData.returnPolicyIds?.join(', ') || ''
            } (${destination.id})`,
          },
        ]
      }
      return [...acc, { ...destination, label: destination.id }]
    }, [])
  }
}
