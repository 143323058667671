import axios from 'axios'
import { getUiServicesEndpoint } from './utils'
import { BookingTypes, DestinationByVendorIds } from '../common/types'

export const destinationsByVendorIdsEndpoint: (
  vendorIds?: number[],
  bookingTypes?: BookingTypes,
  origins?: string[],
) => Promise<string> = async (
  vendorIds?: number[],
  bookingTypes?: BookingTypes,
  origins?: string[],
) => {
  const { key, url } = await getUiServicesEndpoint()
  const baseUrl = `${url}/v1/shipments/destinations?key=${key}`
  const vendorParam =
    vendorIds && vendorIds?.length ? `&vendor_ids=${vendorIds.join(',')}` : ''
  const bookingTypesParam =
    bookingTypes && bookingTypes?.length
      ? `&booking_types=${bookingTypes.join(',')}`
      : ''
  const originsParam =
    origins && origins?.length
      ? `&origin_location_codes=${origins.join(',')}`
      : ''
  return `${baseUrl}${vendorParam}${bookingTypesParam}${originsParam}`
}

export const getDestinationByVendorIdsEndpoint: (
  vendorIds?: number[],
  bookingTypes?: BookingTypes,
  origins?: string[],
) => Promise<DestinationByVendorIds[]> = async (
  vendorIds,
  bookingTypes,
  origins,
) => {
  const endpoint = await destinationsByVendorIdsEndpoint(
    vendorIds,
    bookingTypes,
    origins,
  )
  try {
    const response = await axios.get(endpoint)
    return response?.data?.destinations || []
    // eslint-disable-next-line
  } catch (err: any) {
    throw new Error('Failed to fetch destinationByVendorIds')
  }
}
