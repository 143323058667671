import { getUiServicesEndpoint } from './utils'
import axios from 'axios'
import dayjs from 'dayjs'
import { ConsolidatorShipmentForm } from '../components/Modal/CreateConsolidatorShipment/consolidatorShipmentForm'
import { ReverseShipmentForm } from '../components/Modal/CreateReverseShipment/reverseShipmentForm'

interface ConsolidatorShipmentRequest {
  vendor_id: number
  origin_code: string
  destination_code: string
  carton_qty?: number | null
  pallet?: number | null
  booking_weight?: {
    total_weight: number
    weight_uom: 'pound'
  } | null
  booking_volume?: {
    total_volume: number
    volume_uom: 'pound'
  } | null
  user_provided_pickup_date: string
  bol?: string | null
  appt_num?: string | null
  vendor_type: VendorType
  booking_type: 'CONSOL_MANUAL'
}

enum VendorType {
  CONSOLIDATOR = 'CONSOLIDATOR',
}

interface ReverseShipmentRequest {
  vendor_id: number
  business_unit: string
  origin_code: string
  destination_code: string
  carton_qty?: number
  pallet?: number
  booking_weight?: {
    total_weight: number
    weight_uom: 'pound'
  }
  booking_volume?: {
    total_volume: number
    volume_uom: 'pound'
  }
  user_provided_pickup_date: string
  rtvs_number?: string
  return_authorization_number?: string
  booking_type: 'REVERSE_LOGISTICS_MANUAL'
  commodity_code: string
}

/**
 * Transforms consol formInputs into edge values expected by api
 * @param formInputs
 */
const transformConsolShipmentBody = (
  formInputs: ConsolidatorShipmentForm,
): ConsolidatorShipmentRequest => {
  return {
    vendor_id: formInputs.vendorId,
    origin_code: formInputs.originCode,
    destination_code: formInputs.destLocCode,
    user_provided_pickup_date: dayjs(formInputs.pickupDate).format(
      'YYYY-MM-DD',
    ),
    bol: formInputs.bol || null,
    appt_num: formInputs.appointmentNumber || null,
    vendor_type: VendorType.CONSOLIDATOR,
    booking_type: 'CONSOL_MANUAL',
  }
}

/**
 * Transforms reverse logistics formInputs into edge values expected by api
 */
const transformReverseShipmentBody = (
  formInputs: ReverseShipmentForm,
): ReverseShipmentRequest => {
  // Implement the transformation logic for ReverseShipmentForm
  return {
    booking_type: 'REVERSE_LOGISTICS_MANUAL',
    business_unit: formInputs.businessUnit!,
    vendor_id: formInputs.vendorId!,
    origin_code: formInputs.originCode!,
    destination_code: formInputs.destLocCode!,
    carton_qty: formInputs.carton,
    pallet: formInputs.pallet,
    booking_weight: {
      total_weight: formInputs.totalWt!,
      weight_uom: 'pound',
    },
    user_provided_pickup_date: dayjs(formInputs.pickupDate).format(
      'YYYY-MM-DD',
    ),
    rtvs_number: formInputs.rtvsNumber,
    return_authorization_number: formInputs.returnAuthorizationNumber,
    commodity_code: formInputs.commodityCode!,
  }
}

function isConsolidatorShipmentForm(
  shipment: any,
): shipment is ConsolidatorShipmentForm {
  return (shipment as ConsolidatorShipmentForm).bookingType === 'CONSOL_MANUAL'
}

function isReverseShipmentForm(shipment: any): shipment is ReverseShipmentForm {
  return (
    (shipment as ReverseShipmentForm).bookingType === 'REVERSE_LOGISTICS_MANUAL'
  )
}

export const postNewNonPoShipmentDataEndpoint = async (
  createdShipment: ConsolidatorShipmentForm | ReverseShipmentForm,
) => {
  try {
    const { key, url } = await getUiServicesEndpoint()
    const endpoint = `${url}/v1/non_po_shipment?key=${key}`

    let body
    if (isConsolidatorShipmentForm(createdShipment)) {
      body = transformConsolShipmentBody(createdShipment)
    } else if (isReverseShipmentForm(createdShipment)) {
      body = transformReverseShipmentBody(createdShipment)
    } else {
      throw new Error('Unknown shipment type')
    }

    const response = await axios.post(endpoint, body)
    return Promise.resolve(response)
  } catch (error) {
    return Promise.reject(error)
  }
}
