import axios from 'axios'
import { getUiServicesEndpoint } from './utils'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { ReverseOriginResponse } from '../components/FormInputs/ReverseOrigin'

export const fetchReverseOriginLocations = async () => {
  const { key, url } = await getUiServicesEndpoint()
  const endpoint = `${url}/v1/reverse_logistics_origin_locations?key=${key}`
  try {
    return axios
      .get<ReverseOriginResponse[]>(endpoint)
      .then((response) => response.data)
  } catch (err: any) {
    const message = `Failed to load Reverse Origin Locations`
    logEvent(
      {
        message: JSON.stringify({
          endpoint: endpoint,
          error: err?.response?.data,
          message: message,
          type: 'REQUEST_FAILURE',
          user: localStorage?.userInfo,
        }),
        url: window.location.href,
      },
      { level: LogLevel.Error },
    )

    throw new Error(message)
  }
}
