import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import { getUiServicesEndpoint } from './utils'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { isEmpty, mapKeys, camelCase } from 'lodash'
import { purchaseOrderKey } from 'common/utils'
import { DateInterface } from 'components/TimeBeforeRelease'

export const getExpectedReleaseDays = (
  expectedReleaseDate: Date,
  dateNow: number,
): DateInterface => {
  if (!expectedReleaseDate) {
    return { days: '—' }
  }
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(duration)
  const now = dayjs(dateNow).tz('America/Chicago')
  const end = dayjs(expectedReleaseDate).tz('America/Chicago')
  const timeUntilRelease = dayjs.duration(end.diff(now))
  if (
    timeUntilRelease.get('days') === 0 &&
    timeUntilRelease.get('hours') >= 0
  ) {
    return {
      days: timeUntilRelease.get('days'),
      hours: timeUntilRelease.get('hours'),
    }
  }
  return { days: Math.floor(timeUntilRelease.asDays()) }
}

export const transformPaginationMetadata = (data: {
  total_count?: number
  current_page?: number
  total_pages?: number
}) => {
  if (isEmpty(data)) {
    return {}
  }

  return {
    totalCount: data.total_count,
    currentPage: data.current_page,
    totalPages: data.total_pages,
  }
}

export const transformPurchaseOrders = (data: any, dateNow = Date.now()) => {
  if (!Array.isArray(data)) {
    return []
  }
  return data.reduce((prev: any, curr: any) => {
    const {
      expected_release_ts,
      department,
      purchase_order_number,
      total_cartons,
      total_cubes,
      total_pallets,
      total_weight,
      total_shipments,
      shipments_pending_review,
      vendor,
      cancelled,
      original_ship_dates,
    } = curr

    const { id: vendor_id, name, origin_location = {} } = vendor

    const {
      city = '',
      country = '',
      logistics_group = '',
      state = '',
      street = '',
      time_zone = '',
      tm_location_id = '',
      vmm_location_id = '',
      zipcode = '',
      business_hours = [],
      closure_dates = [],
    } = origin_location
    let businessHoursWithCamelcase = business_hours.map((el: any) =>
      mapKeys(el, (val, key) => camelCase(key)),
    )

    return {
      ...prev,
      [purchaseOrderKey({ purchaseOrder: purchase_order_number, department })]:
        {
          city,
          country,
          department,
          expectedReleaseDays: getExpectedReleaseDays(
            expected_release_ts,
            dateNow,
          ),
          expectedReleaseTs: expected_release_ts,
          haveAllPOShipmentsBeenReviewed: shipments_pending_review === 0,
          isFailedVendorDetailFetch:
            typeof name !== 'string' || typeof origin_location !== 'object',
          logisticGroup: logistics_group,
          purchaseOrder: purchase_order_number,
          shipmentsPendingReview: shipments_pending_review,
          state,
          street,
          timeZone: time_zone,
          tmLocationId: tm_location_id,
          totalCartons: total_cartons,
          totalCubes: total_cubes,
          totalPallets: total_pallets,
          totalShipments: total_shipments,
          totalWeight: total_weight,
          vendorName: name,
          vmmLocationId: vmm_location_id,
          zipCode: zipcode,
          businessHours: businessHoursWithCamelcase,
          vendorId: vendor_id,
          cancelled: cancelled,
          closureDates: closure_dates,
          originalShipDates: original_ship_dates,
        },
    }
  }, {})
}

export const fetchPurchaseOrder = async (
  vendorIds?: Array<string>,
  purchaseOrders?: Array<string>,
  dateFrom?: string,
  dateTo?: string,
  shipmentIds?: Array<string>,
  loadIds?: Array<string>,
  departmentIds?: Array<string>,
  tmLocIds?: Array<string>,
  bookingCategories?: Array<string>,
  truckLoadOptimizationRefIds?: Array<string>,
  currentPage = 1,
  itemsPerPage = 10,
  destinationsLocCodes?: Array<string>,
) => {
  const { key, url } = await getUiServicesEndpoint()
  const params = {
    vendor_ids: vendorIds?.join(','),
    po_numbers: purchaseOrders?.join(','),
    key,
    page: currentPage,
    per_page: itemsPerPage,
    date_from: dateFrom,
    date_to: dateTo,
    tgt_booking_ids: shipmentIds?.join(','),
    load_ids: loadIds?.join(','),
    department_ids: departmentIds?.join(','),
    origin_location_codes: tmLocIds?.join(','),
    booking_categories: bookingCategories?.join(','),
    destination_location_codes: destinationsLocCodes?.join(','),
    tlo_ref_ids: truckLoadOptimizationRefIds?.join(','),
  }
  const endpoint = `${url}/v1/search/purchase_orders`
  try {
    const response = await axios.get(endpoint, {
      params,
    })
    return response.data || []
  } catch (err: any) {
    const message = `Failed to Fetch Purchase Orders, Please refine your search criteria`
    logEvent(
      {
        message: JSON.stringify({
          endpoint,
          params,
          error: err?.response?.data,
          message: message,
          type: 'REQUEST_FAILURE',
          user: localStorage?.userInfo,
        }),
        url: window.location.href,
      },
      { level: LogLevel.Error },
    )

    throw Error(message)
  }
}
