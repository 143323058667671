import uniq from 'lodash/uniq'
import { useAnalyticsWrapper } from 'hooks/useAnalyticsWrapper'
import Logo from '../../media/shipiq.svg'
import apiConfig from 'config/apiConfig'
import { Card, Heading, Layout } from '@enterprise-ui/canvas-ui-react'

export const Unauthorized = () => {
  const analyticsWrapper = useAnalyticsWrapper()

  const entitlements = uniq([
    ...apiConfig.entitlements.tgtInternalRead,
    ...apiConfig.entitlements.tgtInternalWrite,
    ...apiConfig.entitlements.polRead,
    ...apiConfig.entitlements.polWrite,
    apiConfig.entitlements.reverseLogisticsUser,
    apiConfig.entitlements.merchVendorUser,
    apiConfig.entitlements.consolidatorUser,
  ]).flat()

  analyticsWrapper?.trackTaskDone({
    name: 'ShipIQ login',
    status: 'failure',
  })
  return (
    <Layout darkMode={false}>
      <Card>
        <Logo width="200" />
        <br />
        <Heading size={6}>
          ShipIQ requires one of the following entitlements
        </Heading>
        <br />
        <br />
        <Heading size={6}>
          {`${entitlements
            .filter(
              (entitlement) =>
                entitlement && !entitlement.toString().includes('ADMIN'),
            )
            .join(', ')}`}
        </Heading>
      </Card>
    </Layout>
  )
}
