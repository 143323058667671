import axios from 'axios'
import { getUiServicesEndpoint } from './utils'
import { logEvent, LogLevel } from '@praxis/component-logging'

export type ReverseDestinationResponse = {
  location_id: string
  tm_loc_id: string
  vendor_id: number
  vendor_name?: string
  address: {
    address_line: string
    address_line_two?: string
    city: string
    state: string
    zipcode: string
    country_code?: string
  }
  type: string
  return_policy_ids?: string[]
}

//TODO V2 add filtering based on
// ?vendor_type=MERCHANDISE, ?vendor_type=SALVAGE, ?vendor_type=TARGET_PLUS
export const fetchReverseDestinations = async () => {
  const { key, url } = await getUiServicesEndpoint()
  const endpoint = `${url}/v1/reverse_logistics_destination_locations?key=${key}`
  try {
    return axios
      .get<ReverseDestinationResponse[]>(endpoint)
      .then((response) => response.data)
  } catch (err: any) {
    const message = `Failed to load Reverse Origin Locations`
    logEvent(
      {
        message: JSON.stringify({
          endpoint: endpoint,
          error: err?.response?.data,
          message: message,
          type: 'REQUEST_FAILURE',
          user: localStorage?.userInfo,
        }),
        url: window.location.href,
      },
      { level: LogLevel.Error },
    )

    throw new Error(message)
  }
}
